@font-face {
  font-family: 'fontelico';
  src: url('/font/fontelico.eot?7803838');
  src: url('/font/fontelico.eot?7803838#iefix') format('embedded-opentype'),
       url('/font/fontelico.woff?7803838') format('woff'),
       url('/font/fontelico.ttf?7803838') format('truetype'),
       url('/font/fontelico.svg?7803838#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}


.icon-emo-happy:before { content: '\e800'; } /* '' */
.icon-emo-wink:before { content: '\e801'; } /* '' */
.icon-emo-wink2:before { content: '\e813'; } /* '' */
.icon-emo-unhappy:before { content: '\e802'; } /* '' */
.icon-emo-sleep:before { content: '\e803'; } /* '' */
.icon-emo-thumbsup:before { content: '\e804'; } /* '' */
.icon-emo-devil:before { content: '\e805'; } /* '' */
.icon-emo-surprised:before { content: '\e806'; } /* '' */
.icon-emo-tongue:before { content: '\e807'; } /* '' */
.icon-emo-coffee:before { content: '\e808'; } /* '' */
.icon-emo-sunglasses:before { content: '\e809'; } /* '' */
.icon-emo-displeased:before { content: '\e80a'; } /* '' */
.icon-emo-beer:before { content: '\e80b'; } /* '' */
.icon-emo-grin:before { content: '\e80c'; } /* '' */
.icon-emo-angry:before { content: '\e80d'; } /* '' */
.icon-emo-saint:before { content: '\e80e'; } /* '' */
.icon-emo-cry:before { content: '\e80f'; } /* '' */
.icon-emo-shoot:before { content: '\e810'; } /* '' */
.icon-emo-squint:before { content: '\e811'; } /* '' */
.icon-emo-laugh:before { content: '\e812'; } /* '' */
.icon-spin1:before { content: '\e830'; } /* '' */
.icon-spin2:before { content: '\e831'; } /* '' */
.icon-spin3:before { content: '\e832'; } /* '' */
.icon-spin4:before { content: '\e834'; } /* '' */
.icon-spin5:before { content: '\e838'; } /* '' */
.icon-spin6:before { content: '\e839'; } /* '' */
.icon-firefox:before { content: '\e840'; } /* '' */
.icon-chrome:before { content: '\e841'; } /* '' */
.icon-opera:before { content: '\e842'; } /* '' */
.icon-ie:before { content: '\e843'; } /* '' */
.icon-crown:before { content: '\e844'; } /* '' */
.icon-crown-plus:before { content: '\e845'; } /* '' */
.icon-crown-minus:before { content: '\e846'; } /* '' */
.icon-marquee:before { content: '\e847'; } /* '' */