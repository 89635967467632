/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: url('/font/lato/lato-hairline-webfont.eot');
  src: local('Lato Hairline'), local('Lato-Hairline'), url('/font/lato/lato-hairline-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/lato/lato-hairline-webfont.woff2') format('woff2'),
         url('/font/lato/lato-hairline-webfont.woff') format('woff'),
         url('/font/lato/lato-hairline-webfont.ttf') format('truetype'),
         url('/font/lato/lato-hairline-webfont.svg#latohairline') format('svg');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: url('/font/lato/lato-hairline-webfont.eot');
  src: local('Lato Hairline'), local('Lato-Hairline'),url('/font/lato/lato-hairline-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/lato/lato-hairline-webfont.woff2') format('woff2'),
         url('/font/lato/lato-hairline-webfont.woff') format('woff'),
         url('/font/lato/lato-hairline-webfont.ttf') format('truetype'),
         url('/font/lato/lato-hairline-webfont.svg#latohairline') format('svg');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
  font-display: swap;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('/font/lato/lato-light-webfont.eot');
  src: local('Lato Light'), local('Lato-Light'), url('/font/lato/lato-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/lato/lato-light-webfont.woff2') format('woff2'),
         url('/font/lato/lato-light-webfont.woff') format('woff'),
         url('/font/lato/lato-light-webfont.ttf') format('truetype'),
         url('/font/lato/lato-light-webfont.svg#latolight') format('svg');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('/font/lato/lato-light-webfont.eot');
  src: local('Lato Light'), local('Lato-Light'), url('/font/lato/lato-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/lato/lato-light-webfont.woff2') format('woff2'),
         url('/font/lato/lato-light-webfont.woff') format('woff'),
         url('/font/lato/lato-light-webfont.ttf') format('truetype'),
         url('/font/lato/lato-light-webfont.svg#latolight') format('svg');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
  font-display: swap;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('/font/lato/lato-regular-webfont.eot');
  src: local('Lato Regular'), local('Lato-Regular'), url('/font/lato/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/lato/lato-regular-webfont.woff2') format('woff2'),
         url('/font/lato/lato-regular-webfont.woff') format('woff'),
         url('/font/lato/lato-regular-webfont.ttf') format('truetype'),
         url('/font/lato/lato-regular-webfont.svg#latoregular') format('svg');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('/font/lato/lato-regular-webfont.eot');
  src: local('Lato Regular'), local('Lato-Regular'), url('/font/lato/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/lato/lato-regular-webfont.woff2') format('woff2'),
         url('/font/lato/lato-regular-webfont.woff') format('woff'),
         url('/font/lato/lato-regular-webfont.ttf') format('truetype'),
         url('/font/lato/lato-regular-webfont.svg#latoregular') format('svg');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
  font-display: swap;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('/font/lato/lato-bold-webfont.eot');
  src: local('Lato Bold'), local('Lato-Bold'), url('/font/lato/lato-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/lato/lato-bold-webfont.woff2') format('woff2'),
         url('/font/lato/lato-bold-webfont.woff') format('woff'),
         url('/font/lato/lato-bold-webfont.ttf') format('truetype'),
         url('/font/lato/lato-bold-webfont.svg#latobold') format('svg');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('/font/lato/lato-bold-webfont.eot');
  src: local('Lato Bold'), local('Lato-Bold'), url('/font/lato/lato-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/lato/lato-bold-webfont.woff2') format('woff2'),
         url('/font/lato/lato-bold-webfont.woff') format('woff'),
         url('/font/lato/lato-bold-webfont.ttf') format('truetype'),
         url('/font/lato/lato-bold-webfont.svg#latobold') format('svg');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
  font-display: swap;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('/font/lato/lato-black-webfont.eot');
  src: local('Lato Black'), local('Lato-Black'), url('/font/lato/lato-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/lato/lato-black-webfont.woff2') format('woff2'),
         url('/font/lato/lato-black-webfont.woff') format('woff'),
         url('/font/lato/lato-black-webfont.ttf') format('truetype'),
         url('/font/lato/lato-black-webfont.svg#latoblack') format('svg');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('/font/lato/lato-black-webfont.eot');
  src: local('Lato Black'), local('Lato-Black'), url('/font/lato/lato-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/lato/lato-black-webfont.woff2') format('woff2'),
         url('/font/lato/lato-black-webfont.woff') format('woff'),
         url('/font/lato/lato-black-webfont.ttf') format('truetype'),
         url('/font/lato/lato-black-webfont.svg#latoblack') format('svg');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
  font-display: swap;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100;
  src: url('/font/lato/lato-hairlineitalic-webfont.eot');
  src: local('Lato Hairline Italic'), local('Lato-HairlineItalic'), url('/font/lato/lato-hairlineitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/lato/lato-hairlineitalic-webfont.woff2') format('woff2'),
         url('/font/lato/lato-hairlineitalic-webfont.woff') format('woff'),
         url('/font/lato/lato-hairlineitalic-webfont.ttf') format('truetype'),
         url('/font/lato/lato-hairlineitalic-webfont.svg#latohairline_italic') format('svg');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100;
  src: url('/font/lato/lato-hairlineitalic-webfont.eot');
  src: local('Lato Hairline Italic'), local('Lato-HairlineItalic'), url('/font/lato/lato-hairlineitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/lato/lato-hairlineitalic-webfont.woff2') format('woff2'),
         url('/font/lato/lato-hairlineitalic-webfont.woff') format('woff'),
         url('/font/lato/lato-hairlineitalic-webfont.ttf') format('truetype'),
         url('/font/lato/lato-hairlineitalic-webfont.svg#latohairline_italic') format('svg');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
  font-display: swap;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: url('/font/lato/lato-lightitalic-webfont.eot');
  src: local('Lato Light Italic'), local('Lato-LightItalic'), url('/font/lato/lato-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/lato/lato-lightitalic-webfont.woff2') format('woff2'),
         url('/font/lato/lato-lightitalic-webfont.woff') format('woff'),
         url('/font/lato/lato-lightitalic-webfont.ttf') format('truetype'),
         url('/font/lato/lato-lightitalic-webfont.svg#latolight_italic') format('svg');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: url('/font/lato/lato-lightitalic-webfont.eot');
  src: local('Lato Light Italic'), local('Lato-LightItalic'), url('/font/lato/lato-lightitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/lato/lato-lightitalic-webfont.woff2') format('woff2'),
         url('/font/lato/lato-lightitalic-webfont.woff') format('woff'),
         url('/font/lato/lato-lightitalic-webfont.ttf') format('truetype'),
         url('/font/lato/lato-lightitalic-webfont.svg#latolight_italic') format('svg');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
  font-display: swap;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('/font/lato/lato-italic-webfont.eot');
  src: local('Lato Italic'), local('Lato-Italic'), url('/font/lato/lato-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/lato/lato-italic-webfont.woff2') format('woff2'),
         url('/font/lato/lato-italic-webfont.woff') format('woff'),
         url('/font/lato/lato-italic-webfont.ttf') format('truetype'),
         url('/font/lato/lato-italic-webfont.svg#latoitalic') format('svg');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('/font/lato/lato-italic-webfont.eot');
  src: local('Lato Italic'), local('Lato-Italic'), url('/font/lato/lato-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/lato/lato-italic-webfont.woff2') format('woff2'),
         url('/font/lato/lato-italic-webfont.woff') format('woff'),
         url('/font/lato/lato-italic-webfont.ttf') format('truetype'),
         url('/font/lato/lato-italic-webfont.svg#latoitalic') format('svg');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
  font-display: swap;
}

@font-face {
    font-family: 'droid_sansregular';
    src: url('/font/droidsans/droidsans-webfont.eot');
    src: url('/font/droidsans/droidsans-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/droidsans/droidsans-webfont.woff2') format('woff2'),
         url('/font/droidsans/droidsans-webfont.woff') format('woff'),
         url('/font/droidsans/droidsans-webfont.ttf') format('truetype'),
         url('/font/droidsans/droidsans-webfont.svg#droid_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'droid_sansbold';
    src: url('/font/droidsans/droidsans-bold-webfont.eot');
    src: url('/font/droidsans/droidsans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/font/droidsans/droidsans-bold-webfont.woff2') format('woff2'),
         url('/font/droidsans/droidsans-bold-webfont.woff') format('woff'),
         url('/font/droidsans/droidsans-bold-webfont.ttf') format('truetype'),
         url('/font/droidsans/droidsans-bold-webfont.svg#droid_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}