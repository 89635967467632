.nav-left {
    text-align: left;
}

.nav-right {
    text-align: right;
}

.text-muted {
    color: rgb(218, 218, 218);
}

.scrollable {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.table-fixed {
    table-layout: fixed;
}

.no-event-label {
    font-weight: bold;
    color: darkgrey;
    text-align: center;
}

.event-detail-container {
    border-top: 2px darkgrey solid;
}

.event-detail {
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.calendar-header {
    text-align: center;
    padding-top: 5px;
    font-weight: bold;
    font-size: 18px;
}

.calendar-hour-column, .calendar-week-column {
    width: 50px;
}

.calendar-navbar {
    margin-bottom: 5px;
}

.calendar-event-wrap {
    position: relative;
    height: 100%;
}

.calendar-event {
    position: absolute;
    padding: 2px;
    cursor: pointer;
}

.calendar-event-inner {
    overflow: hidden;
    background-color: #15488a;
    color: white;
    height: 100%;
    width: 100%;
    padding: 2px;
    line-height: 15px;
}

.calendar-cell {
    padding: 0 !important;
    height: 37px;
}

.monthview-primary-with-event {
    background-color: #15488a;
    color: white;
}

.monthview-selected {
    background-color: #009900;
    color: white;
}

.monthview-current {
    background-color: lightgrey;
}

.monthview-eventdetail-timecolumn {
    width: 110px;
    overflow: hidden;
}

.monthview-dateCell {
    cursor: pointer;
}

.monthview-secondary-with-event {
    background-color: rgb(120, 152, 189);
}

.weekview-allday-label, .dayview-allday-label {
    float: left;
    height: 100%;
    line-height: 50px;
    text-align: center;
    width: 50px;
    border: 1px solid #ddd;
    border-right-width: 0;
}

.weekview-allday-content-wrapper, .dayview-allday-content-wrapper {
    margin-left: 50px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
}

.weekview-header, .monthview-datetable {
    margin-bottom: 0;
}

.weekview-header th {
    overflow: hidden;
    white-space: nowrap;
}

.weekview-allday-table, .dayview-allday-table {
    height: 52px;
}

.weekview-allday-content-table, .dayview-allday-content-table {
    min-height: 100%;
    margin-bottom: 0;
}

.gutter-column {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

@media (max-width: 750px) {
    .weekview-allday-label, .dayview-allday-label, .calendar-hour-column, .calendar-week-column {
        width: 32px;
    }

    .weekview-allday-label, .dayview-allday-label {
        padding-top: 4px;
    }

    .table > tbody > tr > td.calendar-hour-column, .table > thead > tr > th.weekview-header-label {
        padding-left: 0;
        padding-right: 0;
        font-size: 12px;
        line-height: 12px;
        vertical-align: middle;
    }

    .weekview-allday-label, .dayview-allday-label {
        line-height: inherit;
    }

    .weekview-allday-content-wrapper, .dayview-allday-content-wrapper {
        margin-left: 32px;
    }

    .calendar-event-inner {
        font-size: 12px;
    }
}

